@import "mixins";
@import "colors";
@import "variables";
@import "normalize";
@import "fonts";

/*--------------------------------------
*********** NAVBAR ***********
--------------------------------------*/

.nav_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  padding: 0.5rem 0;

  .logo {
    height: 160px;
    width: 160px;
    cursor: pointer;
  }

  .locals {
    color: $primary-color;

    font-weight: 600;
    font-size: $fz-28;

    margin-top: 1rem;

    cursor: pointer;
  }
}

/*--------------------------------------
*********** HERO SECTION ***********
--------------------------------------*/

.hero_section_wrapper {
  min-height: 85vh;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;

  z-index: 10;

  .content_container {
    .title {
      color: $primary-color;
      line-height: 1.2em;

      font-size: $fz-72;
      font-weight: 800;
    }

    .subtitle {
      margin-top: 1rem;

      color: $secondary-color;
      line-height: 1.3em;

      font-size: $fz-24;
      font-weight: 500;
    }
  }

  .hero_image {
    position: absolute;
    z-index: -10;
    img {
      height: 800px;
      width: 900px;
      object-fit: cover;
    }
  }
}

/*--------------------------------------
*********** FINANCE SECTION ***********
--------------------------------------*/

.finance_details_wrapper {
  margin-top: 40px;
  // padding-bottom: 4rem;
  min-height: 25.5rem;

  display: flex;
  width: 100%;
  gap: 1rem;

  .finance_details_Diamond_box {
    position: relative;
    min-height: 100%;
    width: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3rem;
    background: $linear-gradient-white;
    z-index: 10;

    .card_image {
      z-index: 2;
      img {
        height: 200px;
        width: 200px;
      }
    }
  }

  .finance_details_box {
    position: relative;
    width: 100%;
    padding: 2rem 0rem;
    z-index: 5;

    .finance_details_title_box {
      position: relative;
      height: 92%;
      width: 100%;
      background: #efeff09a;
      border-radius: 3rem;

      .finance_details_title_Desc {
        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 1rem;
        position: absolute;
        right: 0%;
        top: 0%;
        width: 100%;
        height: 100%;
        z-index: 3;
        border-radius: 3rem;
        padding: 2rem;
        padding-right: 9rem;
      }

      .title {
        width: 100%;
        color: $primary-color;
        line-height: 1.2em;

        font-size: $fz-40;
        font-weight: 800;
      }

      .description {
        width: 70%;
        color: $secondary-color;
        line-height: 1.2em;

        font-size: $fz-25;
        font-weight: 500;
      }
    }

    .finance_details_inner {
      width: 17%;
      height: 35%;
      position: absolute;
      bottom: -12px;

      .finance_details_inner_box {
        position: relative;
        padding: 1rem 1.5rem;
        background: $linear-gradient-green;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
        width: 80%;
        height: 100%;
        border-radius: 2rem;

        .text_box_1 {
          color: white;
          line-height: 1em;
          font-size: $fz-25;
          font-weight: 800;
        }
        .text_box_2 {
          position: absolute;
          width: 100%;
          top: 45px;
          margin-right: -10px;
          background: #efeff0;
          border: 1px solid rgba(0, 0, 0, 0.01);
          border-radius: 1rem;
          padding: 0.5rem 0.5rem;
          color: $primary-color;
          line-height: 1.2em;
          font-size: $fz-25;
          font-weight: 800;
        }
        .text_box_3 {
          color: white;
          line-height: 1em;
          font-size: $fz-25;
          font-weight: 800;
        }
      }
    }
  }
}

.finance_description_wrapper {
  margin-top: 40px;
  padding: 5rem 0rem;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 5rem;

  .title {
    width: 13rem;
    color: $secondary-color;
    line-height: 1.2em;

    font-size: $fz-40;
    font-weight: 800;
  }

  .description {
    flex: 1;
    color: $primary-color;
    line-height: 1.2em;

    font-size: $fz-30;
    font-weight: 500;
  }
}

/*--------------------------------------
*********** STEPS SECTION ***********
--------------------------------------*/

.steps_cards_wrapper {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;

  .title {
    width: 30rem;
    color: $primary-color;
    line-height: 1.2em;

    font-size: $fz-30;
    font-weight: 800;
  }

  .card_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .card_boxes {
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .card_box {
        height: 16rem;
        width: 14rem;

        position: relative;
        background: #efeff0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0rem 1.5rem;
        border-radius: 1rem;

        .card_inner_box {
          height: 100%;

          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;

          z-index: 2;

          background: #efeff0;
          border-radius: 1rem;

          padding: 1rem;

          .image_box {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            .image {
              height: 100px;
            }
          }

          .title {
            text-align: center;
            width: 8rem;
            color: $primary-color;
            line-height: 1.2em;
            font-size: $fz-20;
            font-weight: 800;
          }
        }

        .card_white_shadow {
          z-index: 1;
          position: absolute;
          top: 0%;
          width: 100%;
          height: 20%;
          background: white;
          box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0.7);
        }
      }
    }
  }
}

/*--------------------------------------
*********** MOBILE SECTION ***********
--------------------------------------*/
.mobile_section_wrapper {
  height: 1050px;
  width: 100% !important;
  margin-top: 10rem;
  margin-bottom: 2rem;

  padding-top: 10rem;

  position: relative;

  .hero_image {
    position: absolute;
    z-index: -10;
    top: 0;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .content_container {
    .title {
      color: $primary-color;
      line-height: 1.2em;

      font-size: $fz-72;
      font-weight: 800;
    }

    .subtitle {
      margin-top: 1rem;

      color: $primary-color;
      line-height: 1.3em;

      font-size: $fz-24;
      font-weight: 600;
    }
  }
}

/*--------------------------------------
*********** FOOTER SECTION ***********
--------------------------------------*/
.footer_section {
  background: $primary-color;
  color: #ffffff;
  padding: 40px 20px;

  .footer_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;

    .footer_logo_section {
      flex: 1;
      min-width: 200px;

      .footer_logo {
        max-width: 120px;
        margin-bottom: 15px;
      }

      .footer_description {
        font-size: 14px;
        line-height: 1.6;
      }
    }

    .footer_links,
    .footer_contact,
    .footer_social {
      h4 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 8px;

          a {
            color: #ffffff;
            text-decoration: none;
            font-size: 14px;
            &:hover {
              color: $secondary-color;
            }
          }
        }
      }

      p {
        font-size: 14px;
        line-height: 1.6;

        a {
          color: $secondary-color;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .footer_social {
      .social_icons {
        display: flex;
        gap: 10px;

        a {
          color: #ffffff;
          background: $secondary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          text-decoration: none;
          transition: all 0.3s;

          &:hover {
            background: #ffffff;
            color: $secondary-color;
          }

          svg {
            font-size: 20px;
          }
        }
      }
    }
  }

  .footer_bottom {
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    color: #bbbbbb;
    border-top: 1px solid #444;
    padding-top: 20px;
    a {
      color: $secondary-color;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
