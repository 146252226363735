$primary-color: #094424;
$secondary-color: #86bb40;
$light-green: #b5f704;
$black-color: hsl(240, 12%, 11%);
$error-color: rgb(220, 53, 69);
$linear-gradient-green: linear-gradient(
  51deg,
  #adef05 0%,
  #adef05 8%,
  #7abb20db 46%,
  #64a30d 100%
);
$linear-gradient-white: linear-gradient(
  90deg,
  rgba(245, 245, 245, 1) 20%,
  rgba(248, 248, 248, 1) 40%,
  rgba(255, 255, 255, 1) 90%
);
