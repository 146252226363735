@import "mixins";
@import "colors";
@import "variables";
@import "normalize";
@import "fonts";

/* Mobile Potrait SM */
@media (max-width: 576px) {
}

/* Mobile Landscape SM */
@media (max-width: 767px) {
  .hero_section_wrapper {
    .content_container {
      .title {
        font-size: $fz-36;
      }

      .subtitle {
        font-size: $fz-18;
      }
    }

    .hero_image {
      img {
        height: 600px !important;
        object-position: top !important;
      }
    }

    .btn {
      font-size: $fz-14;
      padding: 0.625rem 0.825rem !important;
    }
  }

  .finance_details_wrapper {
    .finance_details_inner {
      display: none;
      .finance_details_inner_box {
        width: 100% !important;
      }
    }
    .title {
      font-size: $fz-24 !important;
    }

    .description {
      margin-top: 1rem;
      width: 100% !important;
      font-size: $fz-16 !important;
    }
  }

  .footer_section_wrapper {
    .description {
      font-size: $fz-14;
    }
    .contact_us {
      font-size: $fz-14;
    }
  }

  .finance_description_wrapper {
    .title {
      font-size: $fz-24 !important;
    }

    .description {
      font-size: $fz-16 !important;
    }
  }

  .steps_cards_wrapper {
    .card_wrapper {
    }
  }

  .mobile_section_wrapper {
    .content_container {
      .title {
        font-size: $fz-36;
      }

      .subtitle {
        font-size: $fz-18;
      }
    }

    .btn {
      font-size: $fz-14;
      padding: 0.625rem 0.825rem !important;
    }
  }
}

/* Mobile + Tablet MD */
@media (max-width: 991px) {
  .nav_wrapper {
    z-index: 999;

    .logo {
      height: 80px;
      width: 80px;
    }

    .locals {
      font-size: $fz-18;
      z-index: 9999 !important;
    }
  }

  .hero_section_wrapper {
    padding: 2rem;
  }
}

/* Mobile + Tablet + Small Screen Laptop LG */
@media (max-width: 1199px) {
  .hero_section_wrapper {
    margin-top: 0px;

    .hero_image {
      opacity: 10%;

      img {
        object-position: center;
        height: 800px;
        width: auto;
      }
    }
  }

  .finance_details_wrapper {
    padding: 0 !important;

    margin-top: 0 !important;
    margin-bottom: 2rem;

    min-height: auto;

    .finance_details_Diamond_box {
      display: none;
    }

    .finance_details_box {
      margin: 0 !important;
      padding: 0 !important;

      .finance_details_title_box {
        height: 100%;
        .finance_details_title_Desc {
          position: relative;
          padding: 1.5rem !important;
        }
      }

      .finance_details_inner {
        width: 20% !important;
        position: absolute;
        bottom: -24px;
        * {
          font-size: $fz-16 !important;
        }
      }
    }
  }

  .finance_description_wrapper {
    display: block;
    margin: 1rem 0;
    padding: 0.8rem;

    .title {
      width: auto !important;
    }
    .description {
      margin-top: 1rem;
    }
  }

  .steps_cards_wrapper {
    padding: 1.5rem;

    .title {
      width: auto !important;
    }
  }

  .mobile_section_wrapper {
    padding: 0 !important;
    margin: 2rem auto !important;

    height: 400px;

    display: flex;
    align-items: center;

    .hero_image {
      opacity: 15%;

      img {
        height: 400px;
      }
    }
  }
}

/* Tablet Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .finance_description_wrapper {
    margin: 6rem 0 !important;
  }
  .finance_details_wrapper {
  }
  .steps_cards_wrapper {
  }
  .mobile_section_wrapper {
    margin: 6rem 0 !important;
    height: 900px;

    .hero_image {
      img {
        height: 900px;
      }
    }
  }
}

/* Small Monitor Only xl */
@media (min-width: 1200px) and (max-width: 1599px) {
  .hero_section_wrapper {
    .content_container {
      .title {
        font-size: $fz-62;
      }
    }

    .hero_image {
      opacity: 80%;

      img {
        height: 600px;
        width: 700px;
      }
    }
  }

  .finance_details_wrapper {
    .finance_details_inner {
      .finance_details_inner_box {
        width: 100% !important;
      }
    }
    .title {
      font-size: $fz-32 !important;
    }

    .description {
      width: 100% !important;
      font-size: $fz-24 !important;
    }
  }

  .footer_section_wrapper {
    .description {
      font-size: $fz-14;
    }
    .contact_us {
      font-size: $fz-14;
    }
  }

  .finance_description_wrapper {
    .title {
      font-size: $fz-32 !important;
    }

    .description {
      font-size: $fz-24 !important;
    }
  }

  .mobile_section_wrapper {
    height: 900px;
    .content_container {
      .title {
        font-size: $fz-62;
      }
    }
  }
}

/* Large Monitor xxl */
@media (min-width: 1600px) {
  .hero_section_wrapper {
    margin-top: -60px;
  }
}
