@font-face {
  font-family: "Avenir Arabic";
  src: url("../fonts/AvenirArabic-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Arabic";
  src: url("../fonts/AvenirArabic-Heavy.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Arabic";
  src: url("../fonts/AvenirArabic-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Arabic";
  src: url("../fonts/AvenirArabic-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Arabic";
  src: url("../fonts/AvenirArabic-Light1.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@mixin primaryFont($weight: 400) {
  font-family: "Avenir Arabic", sans-serif;
  font-weight: $weight;
}
