// fonts size
$fz-0: 0rem;
$fz-1: 0.063rem;
$fz-2: 0.125rem;
$fz-3: 0.188rem;
$fz-4: 0.25rem;
$fz-5: 0.313rem;
$fz-6: 0.375rem;
$fz-7: 0.438rem;
$fz-8: 0.5rem;
$fz-9: 0.563rem;
$fz-10: 0.625rem;
$fz-11: 0.688rem;
$fz-12: 0.75rem;
$fz-13: 0.813rem;
$fz-14: 0.875rem;
$fz-15: 0.938rem;
$fz-16: 1rem;
$fz-17: 1.063rem;
$fz-18: 1.125rem;
$fz-19: 1.188rem;
$fz-20: 1.25rem;
$fz-21: 1.313rem;
$fz-22: 1.375rem;
$fz-23: 1.438rem;
$fz-24: 1.5rem;
$fz-25: 1.563rem;
$fz-26: 1.625rem;
$fz-27: 1.688rem;
$fz-28: 1.75rem;
$fz-29: 1.813rem;
$fz-30: 1.875rem;
$fz-31: 1.938rem;
$fz-32: 2rem;
$fz-33: 2.063rem;
$fz-34: 2.125rem;
$fz-35: 2.188rem;
$fz-36: 2.25rem;
$fz-37: 2.313rem;
$fz-38: 2.375rem;
$fz-39: 2.438rem;
$fz-40: 2.5rem;
$fz-41: 2.563rem;
$fz-42: 2.625rem;
$fz-43: 2.688rem;
$fz-44: 2.75rem;
$fz-45: 2.813rem;
$fz-46: 2.875rem;
$fz-47: 2.938rem;
$fz-48: 3rem;
$fz-49: 3.063rem;
$fz-50: 3.125rem;
$fz-51: 3.188rem;
$fz-52: 3.25rem;
$fz-53: 3.313rem;
$fz-54: 3.375rem;
$fz-55: 3.438rem;
$fz-56: 3.5rem;
$fz-57: 3.563rem;
$fz-58: 3.625rem;
$fz-59: 3.688rem;
$fz-60: 3.75rem;
$fz-61: 3.813rem;
$fz-62: 3.875rem;
$fz-63: 3.938rem;
$fz-64: 4rem;
$fz-65: 4.063rem;
$fz-66: 4.125rem;
$fz-67: 4.188rem;
$fz-68: 4.25rem;
$fz-69: 4.313rem;
$fz-70: 4.375rem;
$fz-71: 4.438rem;
$fz-72: 4.5rem;
$fz-73: 4.563rem;
$fz-74: 4.625rem;
$fz-75: 4.688rem;
$fz-76: 4.75rem;
$fz-77: 4.813rem;
$fz-78: 4.875rem;
$fz-79: 4.938rem;
$fz-80: 5rem;
$fz-81: 5.063rem;
$fz-82: 5.125rem;
$fz-83: 5.188rem;
$fz-84: 5.25rem;
$fz-85: 5.313rem;
$fz-86: 5.375rem;
$fz-87: 5.438rem;
$fz-88: 5.5rem;
$fz-89: 5.563rem;
$fz-90: 5.625rem;
$fz-91: 5.688rem;
$fz-92: 5.75rem;
$fz-93: 5.813rem;
$fz-94: 5.875rem;
$fz-95: 5.938rem;
$fz-96: 6rem;
$fz-97: 6.063rem;
$fz-98: 6.125rem;
$fz-99: 6.188rem;
$fz-100: 6.25rem;


$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-900: 900;

$shadow: rgba(0, 0, 0, 0.05);
$shadowX: 0px 4px 9px rgba(0, 55, 143, 0.05);

$border: 1px solid rgb(159 165 179 / 20%);
$border-color: rgb(159 165 179 / 10%);

$transition: all 0.25s;


// Font Sizes
.fs-0 { font-size: $fz-0; }
.fs-1 { font-size: $fz-1; }
.fs-2 { font-size: $fz-2; }
.fs-3 { font-size: $fz-3; }
.fs-4 { font-size: $fz-4; }
.fs-5 { font-size: $fz-5; }
.fs-6 { font-size: $fz-6; }
.fs-7 { font-size: $fz-7; }
.fs-8 { font-size: $fz-8; }
.fs-9 { font-size: $fz-9; }
.fs-10 { font-size: $fz-10; }
.fs-11 { font-size: $fz-11; }
.fs-12 { font-size: $fz-12; }
.fs-13 { font-size: $fz-13; }
.fs-14 { font-size: $fz-14; }
.fs-15 { font-size: $fz-15; }
.fs-16 { font-size: $fz-16; }
.fs-17 { font-size: $fz-17; }
.fs-18 { font-size: $fz-18; }
.fs-19 { font-size: $fz-19; }
.fs-20 { font-size: $fz-20; }
.fs-21 { font-size: $fz-21; }
.fs-22 { font-size: $fz-22; }
.fs-23 { font-size: $fz-23; }
.fs-24 { font-size: $fz-24; }
.fs-25 { font-size: $fz-25; }
.fs-26 { font-size: $fz-26; }
.fs-27 { font-size: $fz-27; }
.fs-28 { font-size: $fz-28; }
.fs-29 { font-size: $fz-29; }
.fs-30 { font-size: $fz-30; }
.fs-31 { font-size: $fz-31; }
.fs-32 { font-size: $fz-32; }
.fs-33 { font-size: $fz-33; }
.fs-34 { font-size: $fz-34; }
.fs-35 { font-size: $fz-35; }
.fs-36 { font-size: $fz-36; }
.fs-37 { font-size: $fz-37; }
.fs-38 { font-size: $fz-38; }
.fs-39 { font-size: $fz-39; }
.fs-40 { font-size: $fz-40; }
.fs-41 { font-size: $fz-41; }
.fs-42 { font-size: $fz-42; }
.fs-43 { font-size: $fz-43; }
.fs-44 { font-size: $fz-44; }
.fs-45 { font-size: $fz-45; }
.fs-46 { font-size: $fz-46; }
.fs-47 { font-size: $fz-47; }
.fs-48 { font-size: $fz-48; }
.fs-49 { font-size: $fz-49; }
.fs-50 { font-size: $fz-50; }
.fs-51 { font-size: $fz-51; }
.fs-52 { font-size: $fz-52; }
.fs-53 { font-size: $fz-53; }
.fs-54 { font-size: $fz-54; }
.fs-55 { font-size: $fz-55; }
.fs-56 { font-size: $fz-56; }
.fs-57 { font-size: $fz-57; }
.fs-58 { font-size: $fz-58; }
.fs-59 { font-size: $fz-59; }
.fs-60 { font-size: $fz-60; }
.fs-61 { font-size: $fz-61; }
.fs-62 { font-size: $fz-62; }
.fs-63 { font-size: $fz-63; }
.fs-64 { font-size: $fz-64; }
.fs-65 { font-size: $fz-65; }
.fs-66 { font-size: $fz-66; }
.fs-67 { font-size: $fz-67; }
.fs-68 { font-size: $fz-68; }
.fs-69 { font-size: $fz-69; }
.fs-70 { font-size: $fz-70; }
.fs-71 { font-size: $fz-71; }
.fs-72 { font-size: $fz-72; }
.fs-73 { font-size: $fz-73; }
.fs-74 { font-size: $fz-74; }
.fs-75 { font-size: $fz-75; }
.fs-76 { font-size: $fz-76; }
.fs-77 { font-size: $fz-77; }
.fs-78 { font-size: $fz-78; }
.fs-79 { font-size: $fz-79; }
.fs-80 { font-size: $fz-80; }
.fs-81 { font-size: $fz-81; }
.fs-82 { font-size: $fz-82; }
.fs-83 { font-size: $fz-83; }
.fs-84 { font-size: $fz-84; }
.fs-85 { font-size: $fz-85; }
.fs-86 { font-size: $fz-86; }
.fs-87 { font-size: $fz-87; }
.fs-88 { font-size: $fz-88; }
.fs-89 { font-size: $fz-89; }
.fs-90 { font-size: $fz-90; }
.fs-91 { font-size: $fz-91; }
.fs-92 { font-size: $fz-92; }
.fs-93 { font-size: $fz-93; }
.fs-94 { font-size: $fz-94; }
.fs-95 { font-size: $fz-95; }
.fs-96 { font-size: $fz-96; }
.fs-97 { font-size: $fz-97; }
.fs-98 { font-size: $fz-98; }
.fs-99 { font-size: $fz-99; }
.fs-100 { font-size: $fz-100; }

// Font Weights
.fw-300 { font-weight: $fw-300; }
.fw-400 { font-weight: $fw-400; }
.fw-500 { font-weight: $fw-500; }
.fw-600 { font-weight: $fw-600; }
.fw-700 { font-weight: $fw-700; }
.fw-900 { font-weight: $fw-900; }

// Shadows
.shadow { box-shadow: $shadow; }
.shadow-x { box-shadow: $shadowX; }

// Borders
.border { border: $border; }

// Border Colors
.border-color { border-color: $border-color; }

// Transition
.transition { transition: $transition; }
