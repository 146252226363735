@import "mixins";
@import "colors";
@import "variables";
@import "normalize";
@import "fonts";
/*--------------------------------------
*********** COLORS ***********
--------------------------------------*/
.btn {
  font-size: inherit;
  border-radius: 25px;
  padding: 0.65rem 1rem;
  box-shadow: none !important;
  cursor: pointer !important;

  &:disabled {
    color: $linear-gradient-green !important;
    border-color: $linear-gradient-green !important;
    opacity: 100% !important;
    box-shadow: none !important;
    background-color: transparent !important;
    svg path {
      fill: $linear-gradient-green !important;
    }
  }
}

.btn-sm {
  padding: 0.4rem 0.75rem;
}

.btn-rounded {
  padding: 0.4rem 0.75rem;
  border-radius: 100%;
}

.btn:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
}

.bg-primary {
  background-color: $linear-gradient-green !important;
}

.bg-secondary {
  background-color: #ff7c10 !important;
}

.bg-light {
  background-color: #f3f3f8 !important;
}

.bg-dark {
  background-color: #191920 !important;
}

.btn-primary {
  color: #fff;
  background: $linear-gradient-green;
  border-color: #fff;
  @include boxShadow();
  @include flex();
  svg path {
    fill: #fff;
  }
  .icon {
    filter: invert(100%) sepia(4%) saturate(18%) hue-rotate(73deg)
      brightness(105%) contrast(101%);
  }
}

.btn-primary:hover,
.btn-primary:disabled,
.btn-primary:not(:disabled):not(.disabled):active {
  color: $secondary-color;
  border: 1px solid $secondary-color;
  background: transparent;
  svg path {
    fill: $linear-gradient-green;
  }
  .icon {
    filter: invert(23%) sepia(63%) saturate(2578%) hue-rotate(225deg)
      brightness(95%) contrast(96%);
  }
}

.btn-outline-primary {
  color: $linear-gradient-green;
  border-color: $linear-gradient-green !important;
  background: transparent;
  svg path {
    fill: $linear-gradient-green;
  }
  .icon {
    filter: invert(100%) sepia(4%) saturate(18%) hue-rotate(73deg)
      brightness(105%) contrast(101%);
  }
}

.btn-outline-primary:hover {
  color: #fff;
  border-color: $linear-gradient-green !important;
  background-color: $linear-gradient-green;
  svg path {
    fill: #fff;
  }
  .icon {
    filter: invert(23%) sepia(63%) saturate(2578%) hue-rotate(225deg)
      brightness(95%) contrast(96%);
  }
}

.btn-outline-secondary {
  color: #ff7c10;
  border-color: #ff7c10 !important;
  background: transparent;
  svg path {
    fill: #ff7c10;
  }
  .icon {
    filter: invert(53%) sepia(68%) saturate(2251%) hue-rotate(352deg)
      brightness(101%) contrast(101%);
  }
}

.btn-outline-secondary:hover {
  color: #fff;
  border-color: #ff7c10 !important;
  background-color: #ff7c10;
  svg path {
    fill: #fff;
  }
  .icon {
    filter: invert(100%) sepia(4%) saturate(18%) hue-rotate(73deg)
      brightness(105%) contrast(101%);
  }
}

.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled):active {
  color: #ff7c10;
  border-color: #ff7c10;
  background-color: #fff !important;
  svg path {
    fill: #ff7c10;
  }
  .icon {
    filter: invert(53%) sepia(68%) saturate(2251%) hue-rotate(352deg)
      brightness(101%) contrast(101%);
  }
}

.btn-success {
  color: #fff;
  background: #2acd38;
  border-color: #2acd38;
  @include boxShadow();
  @include flex();
  svg path {
    fill: #fff;
  }
}

.btn-success:hover,
.btn-success:not(:disabled):not(.disabled):active {
  color: #2acd38;
  border-color: #2acd38;
  background: transparent !important;
  svg path {
    fill: #2acd38;
  }
}

.btn-outline-success {
  color: #2acd38;
  border-color: #2acd38 !important;
  background: transparent;
  svg path {
    fill: #2acd38;
  }
}

.btn-outline-success:hover {
  color: #fff;
  border-color: #2acd38 !important;
  background-color: #2acd38;
  svg path {
    fill: #fff;
  }
}

.btn-danger {
  color: #fff;
  background: #ec3939;
  border-color: #ec3939;
  @include boxShadow();
  @include flex();
  svg path {
    fill: #fff;
  }
}

.btn-danger:hover,
.btn-danger:not(:disabled):not(.disabled):active {
  color: #ec3939;
  border-color: #ec3939;
  background: transparent !important;
  svg path {
    fill: #ec3939;
  }
}

.btn-outline-danger {
  color: #ec3939;
  border-color: #ec3939 !important;
  background: transparent;
  svg path {
    fill: #ec3939;
  }
}

.btn-outline-danger:hover {
  color: #fff;
  border-color: #ec3939 !important;
  background-color: #ec3939;
  svg path {
    fill: #fff;
  }
}

.btn-outline-light {
  color: #9ea2b1;
  border-color: #9ea2b1;
  background: transparent;
}

.btn-outline-light:hover {
  color: #191920;
  background: #9ea2b1;
  border-color: #9ea2b1;
}

.btn-outline-white {
  color: #fff !important;
  border-color: #fff;
  background: transparent;
}

.btn-success {
  background: #2acd38 !important;
  border-color: #2acd38 !important;
}

.btn-warning {
  background: #f7b500 !important;
  border-color: #f7b500 !important;
}

.btn-danger {
  background: #fc1717 !important;
  border-color: #fc1717 !important;
}

.btn-secondary {
  background: #ff7c10 !important;
  border-color: #ff7c10 !important;

  svg path {
    fill: #fff;
  }
}

.text-primary,
.hover-primary:hover {
  color: $linear-gradient-green !important;
}

.text-link {
  color: #191920 !important;
  cursor: pointer;
  @include transition;
  svg path {
    fill: #191920;
  }
  .icon {
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(21deg) brightness(94%)
      contrast(104%);
  }
}

.text-link:hover {
  color: $linear-gradient-green !important;
  svg path {
    fill: $linear-gradient-green;
  }
  .icon {
    filter: invert(19%) sepia(95%) saturate(2812%) hue-rotate(235deg)
      brightness(88%) contrast(92%);
  }
}

.text-link.active {
  color: $linear-gradient-green !important;
  svg path {
    fill: $linear-gradient-green;
  }
  .icon {
    filter: invert(19%) sepia(95%) saturate(2812%) hue-rotate(235deg)
      brightness(88%) contrast(92%);
  }
}

.text-link-secondary {
  color: #191920 !important;
  cursor: pointer;
  @include transition;
  svg path {
    fill: #191920;
  }
}

.text-link-secondary:hover {
  color: #ff7c10 !important;
  svg path {
    fill: #ff7c10;
  }
}

.text-link-secondary.active {
  color: #ff7c10 !important;
  svg path {
    fill: #ff7c10;
  }
}

.text-warning {
  color: #f7b500 !important;
}

.text-danger {
  color: #fc1717 !important;
}

.text-muted {
  color: #888 !important;
  svg path {
    fill: #888;
  }
}

.text-dark {
  color: #191920 !important;
}

.border-light {
  border-color: #9ea2b1 !important;
}

.small,
small {
  font-size: 80% !important;
}

/*--------------------------------------
*********** CARD ***********
--------------------------------------*/

.card {
  border: 0;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.card-left {
  border: 0;
  direction: ltr;
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
}

.small-card {
  width: 140px;
  max-width: 100%;
  border-radius: 20px;
}

.radius-20 {
  overflow: hidden;
  border-radius: 20px !important;
}

.width-100 {
  width: 100%;
}

.font-1x {
  font-size: 1.5em;
  line-height: 1;
}

.font-2x {
  font-size: 2em;
  line-height: 1;
}

.font-3x {
  font-size: 3em;
}

.border-t-r-white,
.border-b-l-white {
  background: #fff;
}

.border-b-r-light,
.border-t-l-light {
  position: relative;
  background: #fff;
}

.border-b-r-light:before,
.border-t-l-light:before {
  content: "";
  position: absolute;
  z-index: -1;
  width: 150px;
  height: 150px;
  background: #f3f3f8;
}

.border-b-r-light:before {
  bottom: 0;
}

.border-t-l-light:before {
  top: 0;
}

/*--------------------------------------
*********** ANIMATION ***********
--------------------------------------*/

.animate-box .animated {
  position: relative;
}

.animate-box.animated {
  opacity: 1;
}

.animated {
  animation-duration: 1.4s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1.4s;
  -webkit-animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: none;
    -webkit-transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: none;
    -webkit-transform: none;
  }
}

.fadeInUp,
.fadeInLeft,
.fadeInRight {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}

/***/

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: none;
    -webkit-transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: none;
    -webkit-transform: none;
  }
}

/***/

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: none;
    -webkit-transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: none;
    -webkit-transform: none;
  }
}

/***/

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
}

/***/

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
  -webkit-animation-name: fadeOut;
}

/*--------------------------------------
*********** LOADER ***********
--------------------------------------*/

.pre-load {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  position: fixed;
  background: $linear-gradient-green;

  @include flex;
}

.pre-load img {
  top: 50%;
  left: 50%;
  width: 150px;
  position: absolute;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

/*--------------------------------------
*********** Inputs ***********
--------------------------------------*/

.custom-toggle {
  box-shadow: none;
  background: white;
  border: none;

  @include flex();
  gap: 10px;
  padding: auto;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    background: white;
    border: none;
  }
  &::after {
    opacity: 0;
    display: none;
  }
}

.custom-phone-input {
  .special-label {
    display: none !important;
  }

  .containerStyle {
    &:focus,
    &:active {
      outline: none;
    }
  }
}

.custom-input {
  input,
  select {
    width: 100% !important;
    @include primaryFont;
    @include transition;
    border: 1px solid #f0f0f0;
    border-radius: 6px;
    padding: 12px 12px;
    &:focus {
      outline: none !important;
      border-color: $secondary-color !important;
      box-shadow: none !important;
    }
    &:active,
    &:hover {
      outline: none !important;
      border-color: $secondary-color !important;
      box-shadow: none !important;
    }
    &:disabled {
      border-radius: 0.375rem;
      border: 1px solid rgba(15, 17, 52, 0.25);
      background: var(--Gray, #f0f0f0);
      box-shadow: 0px 0px 10px 0px rgba(114, 159, 197, 0.1);
      cursor: not-allowed;
    }
  }
}

.form-input {
  position: relative;
  width: 100%;
}

.form-input .placeholder {
  position: absolute;
  transition: all 0.3s;
  opacity: 100% !important;
  pointer-events: none;
  background-color: #fff !important;
  color: $primary-color;
  transform: translateY(-10px);
  top: 25px;
  left: 12px;
  width: fit-content;
}

.form-input input:focus + .placeholder,
.form-input input:not(:placeholder-shown) + .placeholder {
  transform: translateY(-10px);
  top: 0px;
  font-size: 14px !important;
}

.custom-select-wrapper {
  &:disabled {
    border: 1px solid rgba(15, 17, 52, 0.25);
    background: var(--Gray, #f0f0f0);
    box-shadow: 0px 0px 10px 0px rgba(114, 159, 197, 0.1);
    cursor: not-allowed;
  }

  .cm-select__value-container {
    &:focus,
    &:active {
      outline: none !important;
    }
  }
  .cm-select__control {
    color: #0f1134 !important;
    font-size: 1rem !important;
    width: 100% !important;
    @include transition;
    border: 1px solid rgba(220, 221, 229, 0.9) !important;
    background: #fff;
    &:focus {
      outline: none !important;
      border-color: $primary-color !important;
      box-shadow: none !important;
    }
    &:active,
    &:hover {
      outline: none !important;
      border-color: $primary-color !important;
      box-shadow: none !important;
    }
    &::placeholder {
      opacity: 0.5 !important;
      font-weight: 300 !important;
    }

    &:disabled {
      border: 1px solid rgba(15, 17, 52, 0.25);
      background: var(--Gray, #f0f0f0);
      box-shadow: 0px 0px 10px 0px rgba(114, 159, 197, 0.1);
      cursor: not-allowed;
    }
  }
  .cm-select__indicator {
    svg {
      path {
        fill: #707070;
      }
    }
  }

  .cm-select__multi-value {
    border-radius: 0.325rem;
    // background: rgba(0, 190, 206, 0.1);
    padding: 0.25rem;
    color: #0f1134 !important;
  }

  .cm-select__multi-value-label {
    color: #0f1134 !important;
    font-weight: 800;
  }

  .cm-select__indicator-separator {
    display: none;
  }

  .cm-select__menu-list {
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #f0f0f0 !important;
      border-radius: 0.625rem;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent !important;
    }
  }

  .hide-scroll {
    overflow: hidden;
  }

  .cm-select__menu {
    .cm-select__menu-list {
      .cm-select__option {
        /* font-size: $fz-14; */
        position: relative;
        padding-right: 2rem;
        padding: 2rem auto;
        color: #0f1134;
        font-weight: 400;
        letter-spacing: 0.0125rem;

        &::placeholder {
          opacity: 0.5 !important;
        }

        &.cm-select__option--is-focused {
          background: rgba(240, 240, 240, 0.5);
        }

        &.cm-select__option--is-selected {
          background: #f0f0f0;
          color: rgba(0, 190, 206, 1);

          // &::after {
          //   content: "";
          //   background-repeat: no-repeat;
          //   background-size: contain;
          //   background-position: center;

          //   color: rgba(0, 190, 206, 1);
          //   position: absolute;
          //   right: 15px;
          //   top: calc(50% - 8px);
          //   height: 15px;
          //   width: 15px;
          // }
        }
      }
    }
  }
}

.custom-select-error {
  .cm-select__control {
    border: 1px solid #dc3545 !important;
  }
}

.custom-checkbox {
  @include flex;

  border: 1px solid $secondary-color;
  min-width: 22px;
  height: 22px;

  background-color: transparent;
  border-radius: 4px;

  & + p {
    color: $primary-color;
  }

  &.active {
    transition: all 200ms ease-in-out;
    border: none !important;
  }
}

.custom-radio {
  @include flex;

  background-color: transparent;
  border-radius: 4px;

  input {
    height: 18px;
    width: 18px;
    margin-bottom: 0.325rem;
    box-shadow: none !important;
    &.active {
      border-color: $secondary-color !important;
      background-color: $secondary-color !important;
    }
  }

  label {
    color: $primary-color;
    margin: 0 !important;
  }

  &.active {
    transition: all 200ms ease-in-out;
  }

  &.error input {
    background-color: $error-color !important;
    border-color: $error-color !important;
  }
}

.custom-select {
  @include flex;

  background-color: transparent;
  border-radius: 4px;

  margin-bottom: 0.225rem;
  box-shadow: none !important;
  outline: none;

  &.active {
    transition: all 200ms ease-in-out;
  }

  &.error {
    background-color: $error-color !important;
    border-color: $error-color !important;
  }
}
/*--------------------------------------
*********** MIX ***********
--------------------------------------*/

.w-fit {
  width: fit-content;
}

.bottom-left-rounded {
  border-bottom-left-radius: 8rem;
}

.bottom-right-rounded {
  border-bottom-right-radius: 8rem;
}

.filter-white {
  filter: invert(100%);
}

.empty-box {
  svg {
    height: 48px !important;
    width: 48px !important;
    path {
      fill: #969bab !important;
    }
  }
}

.text-ellipsis {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.text-ellipsis-2x {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 58px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.common-modal {
  .title {
    color: $primary-color;
    line-height: 1.2em;

    font-size: $fz-24;
    font-weight: 800;
  }

  .subtitle {
    color: $primary-color;
    line-height: 1.3em;
    font-size: $fz-18;
    font-weight: 600;
  }

  .modal-header {
    padding-bottom: 2px;
    .close-icon {
      @include transition;
      cursor: pointer;
      &:hover {
        svg path {
          fill: $secondary-color;
        }
      }
    }
  }

  .modal-content {
    border-radius: 0.625rem;
    padding: 0.425rem;
    border: 1px solid #f0f0f0 !important;
  }

  .modal-footer {
    padding: 0.325rem 0.625rem;
  }
}

.brightness-50 {
  filter: brightness(50%);
}

.direction-ltr {
  direction: ltr !important;
}

.direction-rtl {
  direction: rtl !important;
}
