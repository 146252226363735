@import "mixins";
@import "colors";
@import "variables";
@import "fonts";

* {
  border: 0;
  margin: 0;
  padding: 0;
  outline: none !important;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
  background: #fff;
  @include primaryFont;
}

::-webkit-scrollbar-track {
  background-color: #eaeaea;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #eaeaea;
}

::-webkit-scrollbar-thumb {
  background-color: $primary-color;
}

.ltr {
  direction: ltr;
}

a {
  color: #191920;
}

a:hover,
a:focus {
  color: #3941d1;
}

a,
a:hover,
a:focus,
button,
label {
  cursor: pointer;
  text-decoration: none;
}

button {
  background: transparent;
}

label {
  margin-bottom: 0.325rem;
  @include primaryFont;
}

::selection {
  color: #fff;
  background: #3941d1;
}

::-moz-selection {
  color: #fff;
  background: #3941d1;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: #888;
}

::-moz-placeholder {
  opacity: 1;
  color: #888;
}

:-ms-input-placeholder {
  opacity: 1;
  color: #888;
}

:-moz-placeholder {
  opacity: 1;
  color: #888;
}

.h3,
h3 {
  font-size: 1.5rem;
}

input,
select,
textarea {
  width: 100%;
}

textarea {
  resize: none;
  height: 150px;
}

ul,
ol {
  list-style-position: inside;
}

a[href=""] {
  display: none;
}

.hide-overflow {
  overflow: hidden;
}

a,
input,
select,
textarea,
button,
table,
tr,
td,
.navbar-collapse,
.search-form,
.nav-link:after,
.hover-primary,
.card,
.card img,
.checkbox-icon,
.radio-icon,
.checkbox-icon i,
.radio-icon i {
  transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
}
