@mixin borderRadius($arc) {
  -moz-border-radius: $arc;
  -webkit-border-radius: $arc;
  border-radius: $arc;
}

@mixin flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin circleBox($wdth, $hght) {
  width: $wdth;
  height: $hght;
  border-radius: 50%;
}

@mixin transition {
  transition: all 0.3s ease-in-out;
}

@mixin boxShadow {
  box-shadow: 2px 5px 10px rgba(57, 65, 209, 0.6);
}

@mixin dropShadow() {
  filter: drop-shadow(10px 5px 5px $linear-gradient-green);
}

@mixin cutomScrollbar {
  $scrollbar-1-thumb-width: 4px;
  $scrollbar-1-thumb-color: #ffc857;
  $scrollbar-1-track-color: #fff;

  // Chrome & Opera
  &::-webkit-scrollbar {
    width: $scrollbar-1-thumb-width;
    height: 38px;

    &-track {
      background-clip: content-box;
      border: ($scrollbar-1-thumb-width/4) solid transparent;
    }

    &-thumb {
      background-color: $scrollbar-1-thumb-color;

      &:hover {
        background-color: mix($scrollbar-1-thumb-color, #000, 90%);
      }
    }
    &-corner,
    &-track {
      background-color: $scrollbar-1-track-color;
    }
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffc857 !important;
  }
  :-webkit-scrollbar-track {
    background-color: #000 !important;
  }

  // Firefox
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-1-thumb-color $scrollbar-1-track-color;
}

@mixin cutomScrollbarX {
  @include cutomScrollbar;
  &::-webkit-scrollbar {
    height: 3px;
  }
}

@mixin border_red {
  border: 1px solid red;
}

@mixin fix_screen {
  height: 100vh;
  width: 100vw;
}
